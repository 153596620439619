<!-- 订单管理页面 -->
<template>
  <div class="orderManagement">
    <div class="top-area clr">
      <!-- <Button type="primary">新增</Button> -->
      <Input
        style="width:240px"
        @on-search="search"
        v-model="searchText"
        class="fr"
        search
        placeholder="请输入..."
      />
    </div>
    <div class="table-area">
      <Table :columns="columns" :data="dataList" size="small" :height="tableHeight">
        <template slot-scope="{ row }" slot="orderStatusName">
          <span style="color:#19be6b" v-if="row.orderStatusName=='已付款'">{{row.orderStatusName}}</span>
          <span style="color:red" v-if="row.orderStatusName=='取消订单'">{{row.orderStatusName}}</span>
        </template>
        <template slot-scope="{ row, index }" slot="action">
          <a
            type="primary"
            size="small"
            style
            @click="edit(index)"
            v-if="row.orderStatusName == '已付款'"
          >退款</a>
          <!-- <Button type="error" size="small" @click="remove(index)">删除</Button> -->
        </template>
      </Table>
    </div>
    <div class="page-area" v-if="dataList.length">
      <Page
        :total="total"
        :current="current"
        :page-size="size"
        show-total
        @on-change="pageChage"
        @on-page-size-change="pageSizeChage"
      ></Page>
    </div>
  </div>
</template>

<script>
import { getOrderList } from '@/api/order.js'

export default {
  data() {
    return {
      searchText: "",
      tableHeight: window.innerHeight - 240,
      total: 0,
      current: 1,
      size: 20,
      columns: [
        {
          title: '订单编号',
          key: 'orderNo'
        },
        {
          title: '订单状态',
          key: 'orderStatusName',
          slot: 'orderStatusName'
        },
        {
          title: '用户昵称',
          key: 'buyUserUserName'
        },
        {
          title: '支付时间',
          key: 'paymentTime'
        },
        {
          title: '操作',
          slot: 'action',
          width: 100
        },
      ],
      dataList: [
        // {
        //   id: 1,
        //   nickName: '订单001',
        //   orderNumber: 'cp256005884994387968',
        //   commodityInformation: 'XXXX套餐',
        //   userMsg: '158****7155 | 50002',
        //   stateName: '已支付',
        //   date: '2024-01-01'
        // },
      ]
    };
  },
  created() {
    this.findPageDataList()
  },
  methods: {
    search() {
      this.current = 1
      this.findPageDataList()
    },
    findPageDataList() {
      getOrderList({ size: this.size, pageIndex: this.current, orderNo: this.searchText }).then(res => {
        if (res.success) {
          this.dataList = res.result.list
          this.total = res.result.total
        }
      })
    },
    remove() { },
    edit() { },
    pageChage(page) {
      this.current = page
      this.findPageDataList()
    },
    pageSizeChage(size) {
      this.size = size
      this.findPageDataList()
    },
  }
}

</script>
<style lang='scss' scoped>
.orderManagement {
}
</style>